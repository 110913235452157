
/*
 * Invoke the bootstrap datepicker on all .datepicker fields
 */
jQuery(document).ready(function() {
	// Set the date picker default format to UK day-first.
	jQuery('.datepicker').datepicker({
		format: 'dd-mm-yyyy',
		changeMonth: true,
		changeYear: true
	});

	// Handle the response from the AJAX delete buttons.
	// Used in front and back end.

	jQuery('a.btn-delete').bind('ajax:success', function(event, data, xhr) {
		if (data.url) {
			if (data.url == 'reload') {
				location.reload();
			} else {
				location.href = data.url;
			}
		}
	}).bind('ajax:error', function(xhr, status) {
		swal({
			title: 'Item cannot be deleted',
			text: typeof(status.responseJSON) != "undefined" ? status.responseJSON.message : 'Unexpected error.',
			type: 'error'
		});
	});
});
